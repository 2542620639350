import React, { useLayoutEffect, useState } from "react";
import PopupModal from "../Common/Popup";
import { ApiPostCall } from "../Functions/connector";
import PayBillModal from "./PayBillModal";
import $ from "jquery";
import { useParams } from "react-router-dom";

export default function PayBillFun(props) {
  const { RC } = useParams();
  const [payBillTotalData, setPayBillTotalData] = useState({
    grandTotal: 0,
    payBillTotalArray: [],
  });
  const [callBackFun, setCallBackFun] = useState("true");
  const [ValidRC, setValidRC] = useState(
    RC != undefined && RC != null && RC != ""
  );
  useLayoutEffect(() => {
    if (ValidRC) localStorage.clear();
    const token = window.localStorage.getItem("Token");
    var urlencoded = new URLSearchParams();

    if (ValidRC) {
      urlencoded.append("Token", "PayBill");
      urlencoded.append("RentalCode", RC);
    } else {
      urlencoded.append("Token", token);
      urlencoded.append("RentalCode", "0");
    }

    ApiPostCall("/RouterParser/Invoices", urlencoded).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        try {
          let data = [];
          let dataArray = [];
          var grandTotal = 0;
          if (
            result != "No data found!" &&
            result !=
            "{ Error = Invalid length for a Base-64 char array or string. }"
          ) {
            const responseRs = JSON.parse(result);
            data = responseRs[0];
            console.log(data);
            for (let i = 0; i < data.length; i++) {
              if (data[i].BalanceDue != 0) {
                dataArray.push({
                  BalanceDue: data[i]?.BalanceDue,
                  BillID: data[i]?.BillID,
                  OrderCode: data[i]?.OrderCode,
                  PhoneNumber: data[i]?.PhoneNumber,
                });
                grandTotal += data[i]?.BalanceDue;
              }
            }
            setPayBillTotalData({
              ...payBillTotalData,
              grandTotal: Math.abs(grandTotal),
              payBillTotalArray: dataArray,
            });
          }
        } catch (error) {
          console.log(error);
        } finally {
          $(".invoice-loading-msg").addClass("d-none");
        }
      }
    });
  }, []);
  return (
    <>
      {!ValidRC && <div className="p-0 userProfileGreet">Balance due</div>}

      <div
        className="m-0 p-0 phoneresponsv"
        style={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}
      >
        {!ValidRC && (
          <div className=" p-0 payBillTotalFont cl14245">
            ${payBillTotalData.grandTotal?.toFixed(2)}
          </div>
        )}

        <div className="p-0" style={{ textDecoration: "underline" }}>
          <PopupModal
            dfToken={ValidRC}
            type="submit"
            value="Submit"
            className=""
            class="payNowTotalStyle p-0"
            btnName={ValidRC ? "" : "Pay Now!"}
            size="ExtendRentalCardSize deleteCardDismissBtn"
            backdrop={callBackFun}
          >
            <PayBillModal
              ValidRC={ValidRC}
              PB_data={payBillTotalData.payBillTotalArray}
              grandTotal={payBillTotalData.grandTotal?.toFixed(2)}
              setCallBackFun={setCallBackFun}
              Status={props.Status}
            />
          </PopupModal>
        </div>
      </div>
    </>
  );
}
